<template>
  <div class="topic">
    <div class="list_box">
      <router-link
        v-for="item in articleList"
        :key="item.id"
        class="item"
        :to="{ path: '/articledetails', query: { id: item.id } }"
      >
        <h3 class="title">{{ item.title }}</h3>
        <div class="info">
          <div class="time">
            {{ $filters.timeFormat(item.created_at, 'yyyy年MM月dd日') }}
          </div>
          <div class="read">阅读：{{ item.readed || 0 }}</div>
          <div class="label">
            {{ item.classification?.name }}
          </div>
        </div>
        <div class="details">
          <div class="cover">
            <img class="img" :src="item.cover" alt="图片" />
          </div>
          <div class="text">
            {{ item.content }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { PropType, computed } from 'vue';
import removeMd from 'remove-markdown';

export default defineComponent({
  name: 'Topic',
  props: {
    list: {
      type: Array as PropType<TArticle[]>,
      default() {
        return [];
      },
    },
  },
  setup(props) {
    const articleList = computed(() => {
      return props.list.map((item: TArticle) => {
        return {
          ...item,
          content: removeMd(item.content || '').slice(0, 300),
        };
      });
    });

    return { articleList };
  },
});
</script>

<style lang="less" scoped>
@import "../../styles/config.less";
@import "../../styles/lib.less";
.topic {
  width: 100%;
  padding: 0 0.1rem;
  .list_box {
    .item {
      display: block;
      margin-top: 0.2rem;
      padding: 0.1rem 0.2rem;
      background-color: @c_tips;
      border-radius: 0.05rem;
      &:first-child {
        margin-top: 0;
      }
      .title {
        display: block;
        padding: 0.05rem 0;
        font-size: 0.2rem;
        color: @c_333;
        line-height: 1.5;
        font-weight: bold;
        .text_ellipsis;
      }
      .info {
        padding: 0.1rem 0;
        font-size: 0.14rem;
        color: @c_text;
        line-height: 1.2;
        display: flex;
        align-items: center;
        div {
          flex-basis: 20%;
          white-space: nowrap;
        }
      }
      .details {
        padding: 0.1rem 0;
        display: flex;
        align-items: flex-start;
        .cover {
          width: 1.5rem;
          margin-right: 0.2rem;
        }
        .img {
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.1rem;
          border-radius: 0.05rem;
          object-fit: cover;
        }
        .text {
          flex: 1;
          font-size: 0.16rem;
          color: @c_title;
          line-height: 1.8;
          text-align: justify;
          -webkit-line-clamp: 5;
          .multi_text_ellipsis;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .topic {
    padding: 0;
    .list_box {
      .item {
        padding: 0.1rem;
        .info {
          justify-content: space-between;
        }
        .details {
          .cover {
            width: 1rem;
          }
          .img {
            width: 1rem;
            height: 1rem;
          }
          .text {
            font-size: 0.14rem;
            line-height: 1.5;
            -webkit-line-clamp: 4;
          }
        }
      }
    }
  }
}
</style>
